import React, { useState, useRef } from 'react'
import { useNavigate } from "react-router-dom"

import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'

import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

const Provision = () => {
  const navigate = useNavigate()

  const handleSubmit = async function (apiUrl, provisionCode) {
    try {
      let url = apiUrl + '/api/v2/zh-HK/pos_pos/internal_apps/provision?includes=active_id_secret_pair'
      let response = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          "schema_version": "2024-04-25",
          "data":{ "internal_app":{ "provision_code": provisionCode } }
        })
      })

      if (response.ok && (response.status >= '200' && response.status < '400')) {
        let resData = await response.json()
        let secretPair = resData.data.internal_app.active_id_secret_pair

        localStorage.setItem('ApiUrl', apiUrl || '')
        localStorage.setItem('AppId', secretPair.app_id || '')
        localStorage.setItem('AppSecret', secretPair.app_secret || '')
        navigate("/")
      } else {
        console.log("Error #1", response)
      }
    } catch(err) {
      console.log("Error #2", err)
    }
  }

  const [apiUrl, setApiUrl] = useState([])
  const [provisionCode, setProvisionCode] = useState([])
  const apiUrlRef = useRef()
  const provisionCodeRef = useRef()

  const handleApiUrlChange = (event) => {
    setApiUrl(event.target.value);
  }

  const handleProvisionCodeChange = (event) => {
    setProvisionCode(event.target.value);
  }

  return (
<Container component="main" maxWidth="xs">
  <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
      <LockOutlinedIcon />
    </Avatar>
    <Typography component="h1" variant="h5">Provision App</Typography>
    <TextField margin="normal" required fullWidth autoFocus label="API URL"
      inputRef={apiUrlRef} value={apiUrl} onChange={handleApiUrlChange} />
    <TextField margin="normal" required fullWidth autoFocus label="Provision Code"
      inputRef={provisionCodeRef} value={provisionCode} onChange={handleProvisionCodeChange} />
    <Button fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}
      onClick={()=>{handleSubmit(apiUrlRef.current.value, provisionCodeRef.current.value)}}
    >
      Provision
    </Button>
  </Box>
</Container>
  )
}

export default Provision
