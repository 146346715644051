import _ from 'lodash'
import React, { useState, useEffect, useRef } from 'react'
import queryString from 'query-string'

let interval_timer = null
let error_count = 0

const styles = {
  container: {
  },
  wrapper: {
    padding: 10,
  },
  box: {
    border: '1px solid black',
    borderRadius: 10,
    margin: 10,
    padding: 10,
  },
  text: {
    fontSize: 50,
    marginBottom: 10,
  },
  dot: {
    borderRadius: '50%',
    width: 20,
    height: 20,
    marginRight: 5,
  },
  status: {
    display: 'flex',
    flexDirection: 'row',
  },
  green: {
    color: 'green',
  },
  red: {
    color: 'red',
  },
  greenBg: {
    backgroundColor: 'green',
  },
  redBg: {
    backgroundColor: 'red',
  },
  errorBox: {
    background: 'red',
    color: 'white',
    padding: 10,
  },
  seperator: {
    margin: '0 5px',
  },
  refreshedAt: {
    padding: '5px 32px',
    backgroundColor: 'yellow',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  }
}

const qs = queryString.parse(window.location.search, { arrayFormat: 'comma'})
let CODES = _.isString(qs.codes) && !_.isEmpty(qs.codes) ? [qs.codes] : qs.codes || []
const FETCH_INTERVAL = Math.max(parseInt(qs.fetch_interval) || 0, 1*60*1000) // 1 minute minimum
const MAX_ERROR_LIMIT = Math.max(parseInt(qs.max_error_limit) || 1000, 3) // 1000 default, minimum 3
// console.log('%c qs', 'color:red', qs)
// console.log('%c CODES', 'color:red', CODES)
// console.log('%c FETCH_INTERVAL', 'color:red', FETCH_INTERVAL)
// console.log('%c MAX_ERROR_LIMIT', 'color:red', MAX_ERROR_LIMIT)

const fetchHardwares = async (hardwareCodes) => {
  try {
    const apiUrl = localStorage.getItem('ApiUrl')
    const appId = localStorage.getItem('AppId')
    const appSecret = localStorage.getItem('AppSecret')
    const params = queryString.stringify({
      schema_version: '2024-04-25',
      page_size: 100,
      'includes[]': ['internal_data'],
      'code_eq[]': hardwareCodes,
    })
    const url = `${_.trim(apiUrl,'/')}/api/v2/zh-HK/pos_pos/hardwares?${params}`
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-ACCESS-KEY": appId,
        "X-SECRET-KEY": appSecret
      }
    })

    if (response.ok && (response.status >= '200' && response.status < '400')) {
      return await response.json()
    } else {
      throw await response.json()
    }
  } catch(err) {
    throw err
  }
}

const synth = speechSynthesis

function getVoices() {
  let options = []
  let index = 0
  for(let voice of synth.getVoices()){
    if (/zh-HK|zh_HK/.test(voice.lang)) {
      options.push({ 
        index,
        name: voice.name,
        lang: voice.lang,
      })
    }
    index++
  }
  return options
}

function textToSpeech(text, voiceIndex) {
  const utterance = new SpeechSynthesisUtterance(text)
  utterance.voice = synth.getVoices()[voiceIndex]
  utterance.rate = 1
  utterance.lang = 'zh-HK'
  synth.speak(utterance)
}

function dataToSpeech(data, voiceIndex) {
  let text = ''
  if (!_.isEmpty(data)) {
    for (let item of data) {
      const code = _.get(item, 'internal_data.printer_information.status_code')
      const name = _.get(item, 'name')
      const message = _.get(item, 'internal_data.printer_information.message')
      if (code !== 0) {
        // text += `${name}, 有問題。`
        text += `${name}, ${message}。`
      }
    }
    if (!_.isEmpty(text)) {
      textToSpeech(`${text} 請盡快處理。`, voiceIndex)
    }
  }
}

function getDate(date = undefined) {
  const d = new Date()
  let year = d.getFullYear()
  let month = d.getMonth()+1
  let day = d.getDate() 
  let hours = d.getHours()
  let minutes = d.getMinutes()
  let seconds = d.getSeconds()
  if (month < 10) month = `0${month}`
  if (day < 10) day = `0${day}`
  if (hours < 10) hours = `0${hours}`
  if (minutes < 10) minutes = `0${minutes}`
  if (seconds < 10) seconds = `0${seconds}`
  
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
}

const PrinterStatusDisplay = () => {
  const voiceList = useRef()
  const [data, setData] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null)
  const [refreshTime, setRefreshTime] = useState(getDate())

  useEffect(() => {    
    if (!_.isEmpty(CODES)) {
      const fetchData = () => {
        fetchHardwares(CODES)
          .then((response) => {
            const _data = _.get(response, 'data.hardwares')
            setData(_data)
            dataToSpeech(_data, voiceList.current.value)
            if (_.isEmpty(_data)) {
              setErrorMessage(`Could not find any hardware matching ${CODES}`)
            } else {
              setErrorMessage(null)
            }
            setRefreshTime(getDate())
          }).catch((error) => {
            error_count++
            if (error.code === 401 || error_count > MAX_ERROR_LIMIT) {
              console.log('401 OR REACHED MAX ERROR LIMIT')
              clearInterval(interval_timer)
              error_count = 0
            } else {
              setErrorMessage(error.errors)
            }
        })
      }
      interval_timer = setInterval(fetchData, FETCH_INTERVAL)  
      fetchData() // execute first fetch
    } else {
      setErrorMessage('Please specify hardware codes in address bar')
    }
    return () => {
      clearInterval(interval_timer)
      error_count = 0
    }
  }, [])

  return (
    <div style={styles.container}>
      { !_.isEmpty(errorMessage) && <div style={styles.errorBox}>{JSON.stringify(errorMessage)}</div> }
      <div style={styles.refreshedAt}>
        <div>最後刷新於: {refreshTime}</div>
        <div><select ref={voiceList}>{_.map(getVoices(), (voice, idx) => 
          <option key={`voice-list-option-${idx}`} value={voice.index} label={`${voice.name} (${voice.lang})`} />
        )}</select></div>
      </div>
      <div style={styles.wrapper}>
        {_.map(data, (item, id) => {
          const name = _.get(item, 'name')
          const code = _.get(item, 'internal_data.printer_information.status_code')
          const updatedAt = _.get(item, 'internal_data.printer_information.updated_at')
          const message = _.get(item, 'internal_data.printer_information.message')
          let textStyle = {}
          let dotStyle = {}
          let statusStyle = {}
          if (!_.isEmpty(message)) {
            statusStyle = code === 0 ? styles.green : styles.red
            textStyle = code === 0 ? styles.green : styles.red
            dotStyle = code === 0 ? styles.greenBg : styles.redBg
            statusStyle = code === 0 ? styles.green : styles.red
          }
          return (
            <div key={`box-${id}`} style={styles.box}>
              <div style={{...styles.text, ...textStyle}}>
                <div>[{name}] {message}</div>
              </div>
              { !_.isEmpty(updatedAt) && <div style={{...styles.status, ...statusStyle}} onClick={() => textToSpeech(`${name}, ${message}。`, voiceList.current.value)}>
                <div style={{...styles.dot, ...dotStyle}}></div>
                <div>{code}</div>
                <div style={styles.seperator}>|</div>
                <div>{updatedAt}</div>
              </div> }
            </div>    
          )
        })}
      </div>
    </div>
  )
}

export default PrinterStatusDisplay
