import _ from 'lodash'
import React, { useState, useEffect } from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const PrivateRoute = () => {
  const [isProcessing, setIsProcessing] = useState([])
  const [isAuth, setIsAuth] = useState([])

  const fetchConfig = async function (setIsAuth) {
    try {
      let apiUrl = localStorage.getItem('ApiUrl')
      let appId = localStorage.getItem('AppId')
      let appSecret = localStorage.getItem('AppSecret')

      let url = `${_.trim(apiUrl, '/')}/api/v2/zh-HK/pos_pos/me?schema_version=2024-04-25&includes=full_set_model_configs`
      let response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-ACCESS-KEY": appId,
          "X-SECRET-KEY": appSecret
        }
      })

      if (response.ok && (response.status >= '200' && response.status < '400')) {
        let resData = await response.json()
        // let config = resData.data.internal_app.full_set_model_configs.find( x => x.key === 'pos.dual_display').value
        // const config = {
        //   hardware_ids: [57,59]
        // }
        // localStorage.setItem('HardwareIds', JSON.stringify(config.hardware_ids || []))
        setIsAuth(true)
        setIsProcessing(false)
        return
      } else {
        console.log("Error #1", response)
      }
    } catch(err) {
      console.log("Error #2", err)
    }
    setIsAuth(false)
    setIsProcessing(false)
  }

  useEffect(() => {
    setIsProcessing(true)
    fetchConfig(setIsAuth)
  }, [])

  return (
isProcessing ? "Loading" : (isAuth ? <Outlet/> : <Navigate to="/provision" />)
  )
}

export default PrivateRoute;
